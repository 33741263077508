<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
  <div class="card">
    <div class="p-grid" style="margin:0">
      <div class="p-col-4">
        <label>项目类型：</label>
        <Dropdown @keypress="$event.keyCode==13?loadData():{}" style="width:60%" :filter="true" placeholder="请选择" v-model="condition.type" :options="projectTypeList" optionLabel="name" />
      </div>
      <div class="p-col-4">
        <label class="p-col-fixed">项目名称：</label>
        <InputText @keypress="$event.keyCode==13?loadData():{}" style="width:60%;" v-model="condition.name" placeholder="请输入" />
      </div>
      <div class="p-col-4" v-if="hide">
        <label class="p-col-fixed">时间段：</label>
        <Calendar @keypress="$event.keyCode==13?loadData():{}" style="width:240px;" placeholder="请选择" :manualInput="false" dateFormat="yy/mm/dd" selectionMode="range" v-model="condition.timeRange" :numberOfMonths="2"
          :show-icon="true" />
      </div>
      <div class="p-col-4" v-if="hide">
        <label>是否归档：</label>
        <Dropdown @keypress="$event.keyCode==13?loadData():{}" style="width:60%" :filter="true" placeholder="请选择" v-model="condition.isArchive" :options="archiveList" optionLabel="name" />
      </div>
      <div class="p-col" style="display: flex;justify-content: flex-end;padding-right: calc(33.33% - 330px)">
        <Button @click="reset()" style="height:33px;border-color: #d9d9d9" label="重置" class="p-button-outlined p-button-secondary p-mr-2" />
        <Button style="border-color: #12A25D;background: #12A25D;height: 33px" label="查询" class="p-mr-2" @click="loadData()" />
        <Button style="height:33px;color: #12A25D;border-color: #FFFFFF" :label="hideTitle" @click="hideSelection()" :icon="'btn pi ' + (hide ? 'pi-chevron-up' : 'pi-chevron-down')" iconPos="right"
          class="p-button-link" />
      </div>
    </div>
  </div>

  <div v-if="loading" class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-4" v-bind:key="item" v-for="item in cardData">
      <div style="width:100%;border:1px solid rgba(233, 233, 233, 1);">
        <table style="width:100%;border:none;" border="0" cellpadding="0" cellspacing="0">
          <tr style="width:100%;background-color: rgba(255, 255, 255, 1);">
            <td rowspan="2" width="80px;" align="center" valign="top">
              <div v-if="item.isArchive === 0" style="width:48px;height:48px;background-color:rgba(18, 162, 93, 1);border-radius:50px;padding-top:14px;margin-top:15px">
                <i style="color:#fff;font-size:20px;" class="iconfont icon-xiangmu1"></i>
              </div>
              <div v-else style="width:48px;height:48px;background-color:rgba(234, 84, 85, 1);border-radius:50px;padding-top:14px;margin-top:15px">
                <i style="color:#fff;font-size:20px;" class="iconfont icon-xiangmu1"></i>
              </div>
            </td>
            <td>
              <p class="cardName" v-tooltip.top="item.name" @click="viewDetail(item.id)" style="font-size:1.1rem">{{ item.name }}</p>
            </td>
            <td valign="top" align="right">
              <table style="width:120px;">
                <tr>
                  <td align="right">
                    <span v-if="item.isArchive === 0">
                      <Tag value="进行中" class="tag"></Tag>
                    </span>
                    <span v-else>
                      <Tag value="已归档" class="tag-archive"></Tag>
                    </span>
                  </td>
                  <td align="right">
                    <span v-if="item.type.indexOf('生产/') != -1">
                      <Tag value="生产" class="tag-shengchan"></Tag>
                    </span>
                    <span v-else>
                      <Tag value="科研" class="tag-keyan"></Tag>
                    </span>
                  </td>
                </tr>
              </table>

            </td>
          </tr>
          <tr>
            <td colspan="2" style="background-color: rgba(255, 255, 255, 1);">
              <div class="section2_text">编号：{{ item.code }}</div>
              <div class="section2_text oneRow" v-tooltip.top="item.province + item.city + (item.district?item.district:'') + (item.location?item.location:'')">
                地点：{{ item.province + item.city + (item.district?item.district:"") + (item.location?item.location:"") }}</div>
              <div class="section2_text" v-if="item.isArchive === 0 && item.devcount > 0">
                <span style="color:rgba(18, 162, 93, 1);font-weight:bold;">{{ item.devcount }}</span> 个设备
              </div>
              <div class="section2_text" v-else>设备已释放</div>
            </td>
          </tr>
          <tr style="background-color: rgba(247, 249, 250, 1);">
            <td v-if="item.isArchive == 0" colspan="3" style="padding:5px;border:1px solid rgba(233, 233, 233, 1);border-bottom: none;border-left:none;border-right:none;">
              <span style="line-height:32px;margin-left:10px;">
                <span class="iconfont icon-duoyun"></span>
                <label style="color:rgba(0, 0, 0, 1);">
                  <!-- {{item}} -->
                  <label>&nbsp;&nbsp;{{ item.weather?.result?.current_weather.condition ? item.weather?.result?.current_weather.condition : '无天气数据' }}</label>
                </label>
              </span>
              <Button @click="viewDetail(item.id)" icon="pi pi-eye" label="查看数据" style="color:#000000;opacity: 0.45;float:right;" class="p-button-secondary p-button-link" />
            </td>
            <td v-else align="center" colspan="3" style="padding:5px;border:1px solid rgba(233, 233, 233, 1);border-bottom: none;border-left:none;border-right:none;">
              <Button @click="viewDetail(item.id)" icon="pi pi-eye" label="查看数据" style="color:#000000;opacity: 0.45;float:right;" class="p-button-secondary p-button-link" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="!cardData || Object.keys(cardData).length == 0" style="text-align:center;margin-top:60px;width:100%;">
      <img style="width:128px;" src="../../../../assets/images/empty.png" />
      <p style="font-size:14px;color:rgba(0, 0, 0, 0.45);">没有找到项目信息~</p>
    </div>
  </div>
  <div v-if="!loading" class="p-grid" style="justify-content: center;">
    <div class="loading">
        <div class="loader"></div>
      </div>
  </div>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
</template>

<script>
import { useVuelidate } from "@vuelidate/core";

export default {
  components: {},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading:false,
      hide: false,
      hideTitle: "展开",
      archiveList: [
        { name: "全部", code: null },
        { name: "进行中", code: 0 },
        { name: "已归档", code: 1 },
      ],
      projectTypeList: [
        { name: "科研/防风固沙", code: "科研/防风固沙" },
        { name: "科研/根系越冬", code: "科研/根系越冬" },
        { name: "科研/环境监测", code: "科研/环境监测" },
        { name: "生产/育种", code: "生产/育种" },
        { name: "生产/生产田间管理", code: "生产/生产田间管理" },
        { name: "生产/猪饲料-发酵床", code: "生产/猪饲料-发酵床" },
        { name: "生产/种菇", code: "生产/种菇" },
      ],
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [{ label: "项目概览", to: "#" }],
      },
      cardData: [],
      condition: {
        type: null,
        name: null,
        timeRange: null,
        isArchive: null,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    reset() {
      for (let i in this.condition) {
        this.condition[i] = null;
      }
      this.loadData();
    },
    hideSelection() {
      if (!this.hide) {
        this.hideTitle = "收起";
        this.hide = true;
      } else {
        this.hideTitle = "展开";
        this.hide = false;
      }
    },
    loadData() {
      let me = this;
      let parmas = {};
      Object.assign(parmas, this.condition);
      if (parmas.type) {
        parmas.type = parmas.type.code;
      }
      if (parmas.timeRange) {
        parmas.startDate = new Date(parmas.timeRange[0]).toLocaleDateString();
        parmas.endDate = new Date(parmas.timeRange[1]).toLocaleDateString();
        parmas.timeRange = null;
      }
      if (parmas.isArchive) {
        parmas.isArchive = parmas.isArchive.code;
      }
      this.$http("/project/overview", "get", parmas, function (response) {
        me.cardData = response.data;
        me.loading=true
        // console.log(me.cardData);
      });
    },
    viewDetail(id) {
      this.$router.push({
        path: "/data/detail/dtzz",
        query: { projId: id, from: "/data/overview" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  padding: 10px 4px;
}

.section2_text {
  color: #000000;
  opacity: 0.45;
}

.tag {
  border-radius: 50px;
  background-color: rgba(18, 162, 93, 0.1);
  color: rgba(18, 162, 93, 1);
  width: 60px;
}

.tag-archive {
  background-color: rgba(251, 105, 93, 0.1);
  border-radius: 50px;
  color: rgba(234, 84, 85, 1);
  width: 60px;
}

.select1 {
  display: flex;
  justify-content: space-between;
}

.tag-shengchan {
  border-radius: 50px;
  background-color: rgba(18, 162, 93, 0.1);
  color: rgba(18, 162, 93, 1);
  width: 60px;
}

.tag-keyan {
  border-radius: 50px;
  background-color: rgba(245, 187, 2, 0.1);
  color: rgba(245, 187, 2, 1);
  width: 60px;
}

.p-card-content {
  padding: 0px !important;
}

.cardName {
  display: block;
  width: 140px;
  color: #333333;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  cursor: hand;
}

.shadow-box {
  min-height: 100px;
  min-width: 300px;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin: 0px;
  border-radius: 4px;
  background-color: var(--surface-e);
}

::v-deep(.p-calendar-w-btn .p-button) {
  border-color: #12a25d;
  background: #12a25d;
}

.oneRow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 13px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.loader {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
